html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightcyan;
  font-family: "Handlee", cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

#navbar ul {
  margin: 0px 50px;
}

#navbar li {
  display: inline-block;
  margin: 0 15px;
}

#navbar li a {
  padding: 2px 10px;
}

p {
  letter-spacing: 0.5pt;
}

h3 {
  font-weight: bold;
}

#logo {
  float: left;
  margin: 15px 15px 15px 40px;
}

.white-text {
  color: rgba(255, 255, 255, 255);
}

section {
  padding: 0px;
}

.section-header {
  align-items: center;
  background-image: linear-gradient(rgb(18, 38, 94), rgba(0, 0, 0, 9));
}

.section-header #titulo {
  font-family: "Handlee", cursive;
  letter-spacing: 1pt;
}

.section-header #slogan {
  font-family: "Cedarville Cursive", cursive;
}

.section-header a {
  font-family: "Patrick Hand", cursive;
  letter-spacing: 0.5pt;
}

#button-lang {
  width: 95px;
  float: right;
  margin: 10px 10px;
  background-color: transparent;
}

#btn-eng,
#btn-esp {
  border-radius: 25px;
  background-color: transparent;
}

#footer {
  display: grid;
  color: rgb(141, 226, 247);
  background-color: rgb(0, 0, 0);
  height: 100px;
  align-items: center;
  padding: 30px 0px;
}

#main-content,
#contact-form {
  height: 500 px;
  padding: 50px;
}

#main-image {
  display: block;
  margin: 40px auto;
}

#contact-form button {
  padding: 5px;
  width: 100px;
}

/*********** GALERIAS DE IMAGENES ****************************/
$black: rgb(65, 65, 65);
$radius: 10px;
$transition: all 0.25s ease-in-out;

.gallery-container {
  padding-top: 0.9375rem;
}

.gallery-card {
  position: relative;
  overflow: hidden;
  margin-top: 1.2rem;
  margin-bottom: 1.875rem;
}

.gallery-thumbnail {
  background-color: rgb(4, 4, 133);
  max-width: 100%;
  height: auto;
  border-radius: $radius;
}
// Estas lineas permiten que haya un icono oculto que se acciona al pasar el ratón y permite abrir la galeria modal
.card-icon-open {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem !important;
  color: rgb(4, 4, 133);
  cursor: pointer;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: $transition;

  &:focus,
  &:hover {
    color: white;
  }
}

.gallery-thumbnail:focus ~ .card-icon-open,
.gallery-thumbnail:hover ~ .card-icon-open,
.gallery-thumbnail ~ .card-icon-open:focus,
.gallery-thumbnail ~ .card-icon-open:hover {
  opacity: 1;
}
// css para el diálogo (overlay) modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(184, 183, 183, 0.75);
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  padding: 15px;
  background-color: white;
  overflow: auto;
  border-radius: $radius;
  transform: translate(-50%, -50%);
}

.modal-close {
  position: absolute;
  top: 0;
  right: 6px;
  font-size: 1.5rem;
  color: $black;
  transition: $transition;

  &:focus,
  &:hover {
    color: #fff;
  }
}

.card-arrow-left {
  position: absolute;
  font-size: 3rem;
  left: 20px;
}
.card-arrow-right {
  position: absolute;
  font-size: 3rem;
  right: 20px;
}

/*********** FORMULARIO DE CONTACTO ****************************/

#contact {
  display: grid;
  width: 100%;
  grid-template: "lb-from_name" 30px "input-from_name" 40px "lb-replay_to" 30px "input-replay_to" 40px "lb-msg" 40px "tx-msg" 200px "btn-msg" 40px;
  gap: 10px;
  justify-items: center;
  font-size: 1.8em;
}

#contact label {
  margin-top: 10px;
}

#lb-from_name {
  grid-area: lb-from_name;
}

#input-from_name {
  grid-area: input-from_name;
  width: 400px;
}

#lb-replay_to {
  grid-area: lb-replay_to;
}

#input-replay_to {
  grid-area: input-replay_to;
  width: 400px;
}

#lb-msg {
  margin-top: 1.3rem;
  grid-area: lb-msg;
}

#tx-msg {
  grid-area: tx-msg;
  width: 400px;
}

#btn-msg {
  grid-area: btn-msg;
} /* end COMMON CSS ***************************************************/

/* BIG SIZE If the screen size is 800px or more *********************/
@media (min-width: 801px) {
  body {
    font-size: 1.2rem;
  }

  .section-header #titulo {
    font-size: 5rem;
  }

  p,
  .section-header #slogan,
  .section-header a {
    font-size: 2.7rem;
  }
}
/* end media  BIG SIZE ********************************************/

/* MEDIUM SIZE If the screen size is beetween 400px and 800px *******/
@media (min-width: 400px) and (max-width: 800px) {
  body {
    font-size: 0.9rem;
  }

  .section-header #titulo {
    font-size: 3rem;
  }

  #logo {
    margin: 10px 10px 10px 30px;
    width: 25%;
    height: 25%;
  }

  #navbar li {
    margin: 0 10px;
  }

  p,
  .section-header #slogan,
  .section-header a {
    font-size: 1.9rem;
  }
} /* end media  MEDIUM SIZE *****************************************/

/* SMALL SIZE If the screen size is 400px or less *******************/
@media (max-width: 399px) {
  body {
    font-size: 0.8rem;
    padding: 1.1rem;
  }

  .section-header #titulo {
    font-size: 1.8rem;
  }

  #logo {
    margin: 5px 5px 5px 20px;
    width: 20%;
    height: 20%;
  }

  #navbar {
    padding: 0.5rem;
  }

  #navbar li {
    margin: 0 10px;
  }

  #navbar li a {
    padding: 1px 0px;
    margin-left: -15px;
  }

  #button-lang {
    width: 70px;
    margin: 7px 7px;
  }

  #button-lang img {
    width: 80%;
    height: 80%;
  }

  #main-content,
  #contact-form {
    padding: 15px;
  }

  p,
  .section-header #slogan,
  .section-header a {
    font-size: 1.5rem;
  }

  .modal-overlay {
    width: 90%;
    height: 90%;
  }
}

/* end media  SMALL SIZE ******************************************/
